import React from 'react';
import './App.css';

function App() {
  return (
    <div className="main">
      <header className="main-header">
        <a
          className="instagram-link"
          href="https://instagram.com/whycantthisbevegan/"
          rel="noreferrer"
        >
          Ashley R.W. Boutwell
        </a>
      </header>
    </div>
  );
}

export default App;
